.kyc-container {
    display: flex;
    align-items: center;
    column-gap: 3px;
}

.kyc-buttons-container {
    display: flex;
    align-items: center;
    column-gap: 3px;
}

.kyc-button {
    border-radius: 2px;
    color: #FFFFFF;
    font-size: 12px;
    padding: 2px 4px;
    cursor: pointer;
}

.kyc-button-approve {
    background: #1A9434;
}

.kyc-button-decline {
    background: #E08E98;
}

.kyc-view-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    margin-bottom: 5px;
    cursor: pointer;
    background: #D9D9D9;
}