* {
    margin: 0;
    padding: 0;
}

body {
    margin: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #eeeeee;
    height: 100vh;
}

.navLinkActive {
    color: #FFFFFF !important;
    font-weight: 800;
    margin-left: 30px;
}

.navLink {
    text-decoration: none;
    color: #FFFFFF !important;
    margin-left: 30px;
    display: inline-flex;
    align-items: center;
}

.MuiTabs-root {
    margin-bottom: 30px;
}

.dashboardContainer {
    display: flex !important;
}

.dashboardContainer > div {
    margin-right: 10px;
}
.dashboardContainer > div:last-child {
    margin-right: 0;
}

.authContainer {
    width: 450px;
    margin: 0 auto;
    margin-top: 20%;
}

.modal {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    outline: none;
    width: auto;
    height: auto;
    padding: 30px;
    box-shadow: 0 4px 10px black;
    background-color: white;
}

.modalTranslations {
    width: 600px;
}

.modalBody {
    margin-top: 30px;
}

.modalBody > div {
    margin-bottom: 15px;
}

.buttonContainer {
    text-align: right;
    margin-top: 30px;
}

.buttonContainer>button {
    margin-right: 20px;
}

.content {
    width: 99%;
    margin: 0 auto;
}

.flexDiv {
    display: flex;
    width: 100%;
}
.flexDiv > div {
    margin: auto;
}

.flexPaper {
    display: flex;
}

.flexPaper > div {
    padding: 10px;
}

.profileInfoForm {
    display: flex;
    margin-bottom: 10px;
    flex-flow: row wrap;
    align-content: space-between;
    justify-content: space-between
}

.profileInfoFormDiv {
    width: 45%;
}

.formButton {
    width: 100%;
    text-align: right;
    margin: 20px 10px 10px;
}

.blockPaper {
    display: block;
    padding: 10px;
    margin-bottom: 10px;
}

.blockPaper > div {
    margin-bottom: 15px;
}

.profileInfo {
    width: 50% !important;
}
.walletInfo {
    width: 50% !important;
    margin-left: 10px;
}
.userSettings {
    width: 50% !important;
}
.lastLoginInfo {
    width: 50% !important;
    margin-left: 10px;
}

.marginBottom25inside > div {
    margin-bottom: 25px;
}

.colorGray {
    color: #A1A1A1;
}

.margin0 {
    margin: 0 !important;
}

.actionButtons {
    height: 20px;
    font-size: 10px;
    margin: 2px !important;
}

.actionIcon {
    cursor: pointer;
}

.red {
    color: #f44336;
}

.green {
    color: #4caf50;
}

.gold {
    color: #FFD700;
}

#simple-modal-body {
    margin-top: 20px;
    margin-bottom: 20px;
}

#simple-modal-title {
    margin-bottom: 20px;
}

.flexContainer {
    display: flex;
    align-content: space-between;
    justify-content: space-between;
    margin-bottom: 10px;
}

.table > thead > th {
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 7px;
}
.table > tbody > tr > td {
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 7px;
}
.paperCaption {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 700;
}
.searchPanel {
    margin-bottom: 30px;
    padding: 10px;
    align-items: center;
    display: flex;
}

.searchPanel > div {
    margin-left: 30px;
}

.searchPanel > form {
    align-items: center;
    display: flex;
}

.searchButton {
    margin-left: 5px !important;
}

.caption {
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 20px;
}

.simpleFormPaper {
    width: 300px;
    margin: 0 auto;
    padding: 30px;
}

.tableStat {
    margin-top: 20px;
    width: 100%;
}

.tableStat > thead > th, thead > tr > th {
    padding-left: 11px;
    padding-right: 11px;
    padding-bottom: 10px;
    /*text-align: right;*/
    box-sizing: border-box;
}

.tableStat > tr > td {
    padding-left: 11px;
    padding-right: 11px;
    padding-bottom: 7px;
    box-sizing: border-box;
}

.tableStat > tr > td:nth-child(1) {
    padding-left: 0 !important;
}

.tableStat > thead > th:nth-child(1), thead > tr > th:nth-child(1) {
    padding-left: 0 !important;
}

.tableStat > tr > td:last-child {
    padding-right: 0 !important;
}

.tableStat > thead > th:last-child, thead > tr > th:last-child  {
    padding-right: 0 !important;
}

.tableStat > tr > td, thead > th{
    text-align: left;
}

@media screen and (max-width: 768px) {
    .dashboardContainer {
        display: block !important;
    }
    .dashboardContainer > div {
        margin-top: 10px;
        margin-right: 0;
        overflow-x: scroll;
    }
    .authContainer {
        max-width: 90% !important;
    }
    .modal {
        width: 90% !important;
        height: auto !important;
    }
    .modalTranslations {
        width: 90% !important;
    }
    .flexPaper {
        display: block !important;
        overflow: auto;
    }
    .flexPaper > div {
        margin-bottom: 10px;
    }
    .profileInfo {
        display: block !important;
        width: 100% !important;
    }
    .profileInfoFormDiv {
        width: 100%;
    }
    .flexContainer {
        display: block !important;
        width: 100%;
    }
    .walletInfo {
        width: 100% !important;
        margin: 0 !important;
    }
    .userSettings {
        display: block !important;
        width: 100% !important;
    }
    .lastLoginInfo {
        width: 100% !important;
        margin: 10px 0 0;
    }
    .flexDiv {
        display: block !important;
        width: 100% !important;
    }
    .flexDiv > div {
        margin-bottom: 10px !important;
    }
    .searchPanel {
        display: block !important;
    }
}